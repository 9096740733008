import React from 'react'
import { graphql } from 'gatsby'
import { Content, Columns, Card, Section, Container, Button, Image } from 'react-bulma-components'
import { DefaultLayout as Layout } from '../layouts'
import { AboutEnia, Bulmarkdown } from '../components'
import { toEniaAssetURL } from '../helpers'

export const query = graphql`
  query($projectId: ID!) {
    enia {
      project: projects_by_id(id: $projectId) {
        id
        name
        title
        coords
        biomethane_prod
        nm3
        perf
        qty
        digestate_qty
        description
        link
        image {
          id
          filename_download
        }
        partners_id {
          partners_id {
            url
            name
            logo {
              id
              filename_download
            }
          }
        }
      }
    }
  }
`

export default function project({ data }) {
  console.debug(JSON.stringify(data))
  const { project } = data.enia
  const partners = project.partners_id

  if (!project) {
    throw Error('Project not found')
  }
  return (
    <Layout
      pageContext={{
        frontmatter: {
          title: `${project.title} - Enia`,
          description: `Description du projet ${project.tile} `,
        },
      }}>
      <Section size='small' backgroundColor='primary'>
        <Container textAlign={'center'}>
          <h1 className='title is-1' style={{ color: 'white' }}>
            {project.title}
          </h1>
        </Container>
      </Section>
      <Section size='medium' style={{ paddingTop: '2em' }}>
        <Columns style={{ paddingBottom: '1em' }}>
          {Boolean(project.image) && (
            <Columns.Column>
              <Image
                src={toEniaAssetURL(project.image)}
                alt={project.image.filename_download}
                style={{
                  width: '32em',
                  maxWidth: 'calc(100vw - 32px)',
                  marginTop: 16,
                }}
              />
            </Columns.Column>
          )}
          <Columns.Column textAlign={'center'}>
            <Card style={{ marginTop: 16 }}>
              <Card.Header justifyContent='center'>
                <strong className='highlighted title is-5' style={{ padding: '0.5em' }}>
                  {new Intl.NumberFormat('de-DE').format(project?.qty)} tonnes
                </strong>
              </Card.Header>
              <Card.Content>
                <span className='subtitle is-6'>de gisements traités chaque jour</span>
              </Card.Content>
            </Card>
            <Card style={{ marginTop: 32 }}>
              <Card.Header justifyContent='center'>
                <strong className='highlighted title is-5' style={{ padding: '0.5em' }}>
                  {new Intl.NumberFormat('de-DE').format(project?.digestate_qty)} T/an
                </strong>
              </Card.Header>
              <Card.Content>
                <span className='subtitle is-6'>de digestat naturel épandu par an</span>
              </Card.Content>
            </Card>
          </Columns.Column>
          <Columns.Column textAlign={'center'}>
            <Card style={{ marginTop: 16 }}>
              <Card.Header justifyContent='center'>
                <strong className='highlighted title is-5' style={{ padding: '0.5em' }}>
                  {new Intl.NumberFormat('de-DE').format(project?.biomethane_prod)} Nm³/h
                </strong>
              </Card.Header>
              <Card.Content>
                <span className='subtitle is-6'>de biométhane injecté dans le réseau</span>
              </Card.Content>
            </Card>
            <Card style={{ marginTop: 32 }}>
              <Card.Header justifyContent='center'>
                <strong className='highlighted title is-5' style={{ padding: '0.5em' }}>
                  {new Intl.NumberFormat('de-DE').format(project?.perf)} kW/an
                </strong>
              </Card.Header>
              <Card.Content>
                <span className='subtitle is-6'>
                  l’équivalent de la consommation annuelle de
                  {Math.round(new Intl.NumberFormat('de-DE').format(project?.perf / 4679))}
                  &nbsp;foyers
                </span>
              </Card.Content>
            </Card>
          </Columns.Column>
        </Columns>
        <Container>
          <Content>
            <Bulmarkdown>{project.description}</Bulmarkdown>
            {Boolean(project.link) && (
              <Content
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <a href={project.link}>
                  <Button color='primary' colorVariant='light'>
                    Plus d'infos ›
                  </Button>
                </a>
              </Content>
            )}
          </Content>
          {Boolean(partners) && (
            <Container textAlign='center'>
              <h3 className='highlighted title is-3'>Les Partenaires</h3>
              <Columns centered textAlign='center' breakpoint='mobile'>
                {partners.map((partner) => (
                  <Columns.Column key={partner.partners_id.name} style={{ textAlign: '-webkit-center' }}>
                    <a href={partner.partners_id.url}>
                      <Card style={{ margin: '0.5em' }}>
                        <Image
                          src={toEniaAssetURL(partner.partners_id.logo)}
                          alt={partner.partners_id.logo.filename_download}
                          style={{
                            width: '12em',
                            minHeight: '8em',
                            padding: 16,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        />
                        <Card.Content textAlign='center'>
                          <p className='title is-5'>{partner.partners_id.name}</p>
                        </Card.Content>
                      </Card>
                    </a>
                  </Columns.Column>
                ))}
              </Columns>
            </Container>
          )}
          <AboutEnia />
        </Container>
      </Section>
    </Layout>
  )
}
